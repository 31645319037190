.heightfull {
	height: 100vh;
}
.fixed-background {
    background-attachment: fixed;
    background-position: 50% 0;
    background-size: cover;
}

// Fix images not being centered in text
img {
	display: inline-block;
}

.banner-item-hidden {
	display: none;
}

.hidden {
	display: none;
}
#instafeed {
	text-align: center;
	img {
		margin-left: 5px;
		margin-right: 5px;
	}
}
